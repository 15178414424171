import React from "react"
import { graphql } from "gatsby"

import { Layout, PostList, PostPreview, SEO } from "../components"

class BlogIndex extends React.Component {
  render() {
    const _posts = this.props.data.allOrgContent.edges
    const posts = _posts.map(({ node }) => {
      const post = node
      const {
        title,
        date,
        isoDate,
        relativeDate,
        category,
        tags,
        image,
        description,
      } = post.metadata
      const attrs = {
        title: title,
        date: date,
        isoDate: isoDate,
        relativeDate: relativeDate,
        content: post.html,
        tags: tags,
        category: category,
        slug: post.fields.slug,
        image: image,
        description: description,
        wordCount: post.wordCount,
        timeToRead: post.timeToRead,
      }
      return <PostPreview key={post.fields.slug} {...attrs} />
    })

    return (
      <Layout about>
        <SEO title="Blog" />
        <PostList>{posts}</PostList>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    allOrgContent(sort: { fields: metadata___date, order: DESC }) {
      edges {
        node {
          html
          fields {
            slug
          }
          metadata {
            title
            date(formatString: "ll")
            isoDate: date(formatString: "YYYY-MM-DD")
            relativeDate: date(fromNow: true)
            category
            tags
            image
            description
          }
          wordCount
          timeToRead
        }
      }
    }
  }
`
